
import {ContentLoader} from 'vue-content-loader';
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import { ref } from 'vue';
import { enviarNovaSenha, forgotPassword} from "@/services/UsuarioService";
import { hideModal } from '@/core/helpers/dom';

export default{
    name:"modalRecuperacaoSenha",
    components: {
        ContentLoader
    },
    setup() {
        const email = ref('');  
        const emailError = ref('');  
        const emailValid = ref(false);  
        const loadingButton = ref(false);
        
        const validacaoEmail = () => {
            const regex = /^[\w+.]+@\w+\.\w{2,}(?:\.\w{2})?$/;
            if(regex.test(email.value)){
                emailValid.value = true;
                emailError.value = '';
            } else {
                emailValid.value = false;  
                emailError.value = 'Insira um email válido';  
            }
        }

        const handleForgotPassword = async () => {
            loadingButton.value = true;

            const postData = {
                'email': email.value
            }           
         
            forgotPassword(postData).then((r) => {
                if(!r.error){
                    Swal.fire({
                        title: "Email enviado!",
                        text: r.message,
                        icon: "success",
                        showCancelButton: false,
                        confirmButtonColor: "#3085d6",
                        confirmButtonText: "OK!"
                    }).then(()=>{
                        closeModal();
                    })
                }
            }).catch((error) => { 
                Swal.fire({
                    title: "Recuperação de email",
                    text: "Solicitação de recuperação de senha enviada com sucesso, caso o e-mail esteja cadastrado",
                });
            }).finally(() => {
                loadingButton.value = false;
            });                         
        }

        function closeModal(){
            email.value = '';
			const refModal = document.getElementById("modalRecuperacaoSenha");
			hideModal(refModal);
		}

        return {
            email,
            emailError,
            emailValid,
            validacaoEmail,
            handleForgotPassword,
            enviarNovaSenha,
            loadingButton,
            closeModal
        }
    }
}
